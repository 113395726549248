@import url(https://fonts.googleapis.com/css?family=Cairo);
:root {
  --lightGreen: #08cc96;
  --white: #ffffff;
  --gray: #707070;
  --redDanger: #721c24;
  --lightRed: #f8d7da;
}

body {
  margin: 0;
  font-family: Cairo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  font-size: 1rem;
  color: #ffffff;
  color: var(--white);
  background: transparent linear-gradient(180deg, #161616 0%, #313131 100%) 0% 0% no-repeat
    padding-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

table td,
table th {
  border: 1px solid #08cc96;
  border: 1px solid var(--lightGreen);
  padding: 10px;
}

table caption {
  text-align: center;
}

a,
a:visited {
  outline: none;
  text-decoration: none;
}

h1 {
  font-size: calc(1rem + 3vw);
}

h3 {
  font-size: calc(1rem + 1vw);
}

h5,
h6 {
  font-size: calc(0.7rem + 0.5vw);
}

p {
  font-size: calc(0.5rem + 0.7vw);
  word-break: break-word;
}

.p-medium {
  font-size: calc(0.5rem + 0.7vw);
}

.p-small {
  font-size: calc(0.5rem + 0.2vw);
}

.modal-header .close {
  margin: -1rem auto -1rem -1rem;
}

@media screen and (max-width: 450px) {
  .blog-container .body img {
    width: 100% !important;
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
  }
}

.blog-container .body img {
  max-width: 100% !important;
  height: auto !important;
  margin: 0 auto !important;
  display: block !important;
}

.media svg {
  width: calc(1rem + 1vw);
  height: calc(1rem + 1vw);
  color: #08cc96;
  color: var(--lightGreen);
  margin: 0 2px;
}

li.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #fff3;
  border-radius: 0.2rem;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0.2rem;
  margin: 0.3rem;
  transition: all ease-in-out 0.1s !important;
}

li.media:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.disabled {
  opacity: 0.3;
}

.blog-nav {
  position: fixed;
  width: 100%;
  z-index: 10;
  top: 0;
  background-color: rgba(22, 22, 22, 0.5);
}

.nav-item > .nav-link {
  color: #ffffff !important;
  color: var(--white) !important;
  font-size: calc(1rem + 0.2vw);
}

.nav-item.active > .nav-link {
  color: #08cc96 !important;
  color: var(--lightGreen) !important;
  border-bottom: 2px solid #08cc96 !important;
  border-bottom: 2px solid var(--lightGreen) !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.blog-card {
  background: transparent linear-gradient(to right, #313131 0%, #161616 100%) 0% 0% no-repeat
    padding-box;
  color: #ffffff !important;
  color: var(--white) !important;
  border-radius: 5px;
}

.blog-card .card-body {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.blog-card .card-text {
  font-size: calc(0.2rem + 0.7vw);
}

.featured-wrapper {
}

.featured-blogs {
  margin: 0 0 50px 0;
  padding: 50px 0;
}

.featured-blogs,
.blogs-list {
  border-radius: 56px;
  background-color: #313131;
}

.blogs-list {
  border-radius: 56px;
}

.media-list {
  -webkit-animation: slide-list ease-in-out 0.3s;
          animation: slide-list ease-in-out 0.3s;
}

@-webkit-keyframes slide-list {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slide-list {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 450px) {
  .featured-blogs,
  .blogs-list {
    margin: 20px;
  }
}

.blogs-list {
  padding: 36px 53px;
  margin-top: 5rem;
}

.carousel-title h2,
.blogs-list-title h2 {
  font-size: calc(1rem + 2vw);
  color: #08cc96;
  color: var(--lightGreen);
  font-weight: bold;
  margin-bottom: 4rem;
}

.carousel-inner .blog-card {
  background: transparent !important;
}

.carousel-inner .blog-card-title,
.carousel-inner .card-text {
  white-space: break-spaces;
}

.card-text {
  font-style: italic;
}

.container.home {
  position: relative;
  padding-top: 100px;
}

.sidenav {
  position: relative;
  top: 20px;
  left: 10px;
  overflow-x: hidden;
  padding: 8px 0;
  overflow: visible;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: calc(1rem + 1vw);
  color: #2196f3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}

.main {
  margin-top: 30px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: calc(1rem + 0.7vw);
  }
}

.pagination {
  padding: 0;
}

.pagination li {
  padding: 3px 0;
  min-width: 30px;
  margin: 0 5px 0 0;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  color: var(--white);
}

.pagination li.active {
  border-radius: 10px;
  background-color: #08cc96;
}

.pagination li.next,
.pagination li.previous {
  color: #08cc96;
  color: var(--lightGreen);
}

.copyright {
  padding: 5px 0 0;
  margin: 5px 0 0;
  color: #fff;
  background-color: #313131;
  bottom: 0;
  width: 100%;
}

.sort-options input {
  display: none;
}

.categories-filters label,
.sort-options label {
  cursor: pointer;
  opacity: 0.7;
  white-space: nowrap;
  margin: 3px 0;
  color: #08cc96;
  color: var(--lightGreen);
}

.categories-filters .css-1uccc91-singleValue,
.sort-options .css-1uccc91-singleValue {
  color: #ffffff;
  color: var(--white);
}

.categories-filters .css-yk16xz-control,
.categories-filters .css-1pahdxg-control,
.categories-filters .css-26l3qy-menu,
.sort-options .css-yk16xz-control,
.sort-options .css-1pahdxg-control,
.sort-options .css-26l3qy-menu {
  background-color: #313131;
}

.categories-filters .css-1pahdxg-control,
.categories-filters .css-1pahdxg-control:hover,
.sort-options .css-1pahdxg-control,
.sort-options .css-1pahdxg-control:hover {
  border-color: #08cc96;
  border-color: var(--lightGreen);
  box-shadow: 0 0 0 1px #08cc96;
  box-shadow: 0 0 0 1px var(--lightGreen);
}

.blogs-list .alert {
  background: transparent;
  color: #fff;
  opacity: 0.7;
  margin: 10% auto;
}

.filters-title,
.sort-title {
  color: #08cc96;
  color: var(--lightGreen);
  font-size: calc(1rem + 1vw);
}

.blog-container img {
  cursor: pointer;
  border-radius: 10px;
}

.blog-container .title h2 {
  font-size: calc(1rem + 1vw);
  font-weight: 500;
  color: #08cc96;
  color: var(--lightGreen);
}

.blog-container .date p {
  font-size: calc(1rem + 0.5vw);
  font-weight: 300;
}

.sharing-icons {
  width: 27px;
  height: 27px;
}

.blog-container hr {
  border-color: #707070;
  border-color: var(--gray);
}

.similar-blogs h3 {
  margin-top: 50px;
  color: #08cc96;
  color: var(--lightGreen);
  font-size: calc(1rem + 2vw);
}

.blog-container .cover {
  width: calc(40rem + 2vw) !important;
  height: 100%;
  display: block;
  margin: auto;
}

.blog-container .body {
  background: rgb(8 204 150 / 13%);
  padding: 2rem;
  word-break: break-word;
}

@media screen and (max-width: 576px) {
  .blog-wrapper > .container-fluid {
    padding: 0;
  }
  .blog-container .body {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .blog-container .body > div > p > span,
  .blog-container .body > div > ul > li > span {
    font-size: 14px !important;
  }
}

pre {
  text-align: left;
  direction: ltr;
  background-color: #08090a !important;
  border: 1px solid #08090a !important;
  border-radius: 10px;
  padding: 24px !important;
  color: inherit !important;
  width: 80%;
  margin: 30px auto 20px;
}

.blog-card-title {
  color: #ffffff;
  color: var(--white);
}

.blog-card-title:hover {
  color: #08cc96;
  color: var(--lightGreen);
  text-decoration: none !important;
}

.blog-tag {
  background-color: #08cc96;
  background-color: var(--lightGreen);
  color: #ffffff;
  color: var(--white);
  padding: 1px 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

.privacy-terms a {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  color: var(--white);
}

.privacy-terms a:hover {
  color: #08cc96;
  color: var(--lightGreen);
}

.about-us,
.filtered,
.privacy-policy,
.terms-of-use {
  padding: 35px 65px;
  margin: 0 auto 10%;
  border-radius: 5px;
  background-image: linear-gradient(to right, #128b8f, #111d1d);
  min-height: 10vh;
  color: #ffffff;
  color: var(--white);
  box-shadow: 0 0 0 1px #ffffff33;
  overflow-wrap: anywhere;
}

section.filtered-by-tag {
  border-radius: 56px;
  background-color: #313131 !important;
  padding: 1rem;
}

.blog-wrapper {
  background: linear-gradient(90deg, rgb(0 255 43 / 10%), rgb(0 0 0 / 23%)),
    url(/static/media/terms-cover.6653eb6a.svg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-height: 100vh;
  padding-bottom: 2rem;
  padding-top: 3.6rem;
}

.utl-wrapper {
  background: linear-gradient(90deg, rgb(0 255 43 / 10%), rgb(0 0 0 / 23%)),
    url(/static/media/utl-cover.b4168ce8.svg);
  min-height: 100vh;
  padding-bottom: 2rem;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.terms-wrapper {
  background: url(/static/media/terms-cover.6653eb6a.svg) center/cover;
  min-height: 100vh;
  padding-bottom: 2rem;
}

a.privacy-policy-link {
  cursor: pointer;
  text-decoration: none;
}

.main-wrapper {
  padding-top: 2rem;
  background: url(/static/media/main-cover.34cd4bcf.svg) center/cover no-repeat;
  min-height: 98vh;
  height: 100%;
  width: auto;
}

.main-wrapper .type-writer {
  font-size: 3rem;
}

@media only screen and (max-width: 988px) {
  .main-wrapper .type-writer {
    font-size: 2rem;
  }
  .main-wrapper p {
    font-size: 90%;
  }
}

.contact-wrapper {
  padding-top: 2rem;
  background: linear-gradient(184deg, rgb(65 255 97 / 10%), rgb(0 0 0 / 23%)),
    url(/static/media/contact-cover.aef45d7f.svg);
  min-height: 120vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.contact-us-form {
  margin: 0px auto 90px;
}

.contact-us-form label {
  color: #08cc96;
  color: var(--lightGreen);
}

.contact-us-form input,
.contact-us-form textarea {
  border-color: #08cc96;
  border-color: var(--lightGreen);
  background: #d8d9dd;
}
.contact-us-form input:focus,
.contact-us-form textarea:focus {
  background: #dddfe4;
  border-color: #08cc96;
  border-color: var(--lightGreen);
  border-width: 2px;
}

.contact-us-form .btn-success,
.contact-us-form .btn-success:hover {
  background-color: #08cc96;
  background-color: var(--lightGreen);
}

.footer {
  background-color: #161616;
  padding: 32px 0;
}

.fixed-media {
  position: fixed;
  bottom: 300px;
}

/* quote style */
blockquote {
  margin: 10px auto;
  padding: 15px 15px 5px;
  border-right: 5px solid #08cc96;
  border-right: 5px solid var(--lightGreen);
}
blockquote:before {
  display: none;
}
blockquote:not(:first-of-type) {
  margin-top: 0.5em;
}
blockquote p {
  color: #adb5bd;
  font-size: 14pt;
  font-style: italic;
  line-height: 1.4;
  font-family: "PT Serif", Cambria, "Hoefler Text", Utopia, "Liberation Serif",
    "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif;
}
blockquote footer {
  margin-top: 0.5em;
  padding: 0;
  color: #777;
  font-size: 12pt;
  text-align: left;
  font-style: italic;
}
blockquote footer:before {
  content: "— ";
}
blockquote:nth-of-type(even) {
  text-align: right;
  border-left: none;
  border-right: 5px solid #999;
}
blockquote:nth-of-type(even) footer {
  text-align: right;
}
blockquote:nth-of-type(even) footer:before {
  content: "";
}
blockquote:nth-of-type(even) footer:after {
  content: " —";
}

/* Slideshow */
.slideshow {
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  width: 100%;
}

.slide {
  display: inline-block;
  width: 100%;
  padding: 0 50px;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;
  opacity: 0.5;
  background-color: #08cc96;
  background-color: var(--lightGreen);
}

.slideshowDot.active {
  opacity: 1;
}

.page-not-found {
  position: relative;
  text-align: center;
}

.page-not-found p {
  color: #08cc96;
  color: var(--lightGreen);
  font-size: calc(1rem + 1vw);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: bold;
}

@media screen and (max-width: 800px) {
  .page-not-found img {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {
  .page-not-found img {
    width: 100%;
  }
}

.home-ad-container {
  padding: 8px 0;
  background-color: #08cc96;
  background-color: var(--lightGreen);
  border-radius: 20px;
  color: #ffffff;
  color: var(--white);
  width: 80%;
}

/** Swiper **/
.swiper-slide {
  text-align: center;
  height: 100%;
  width: 100% !important;
  padding: 2rem 5.5rem;
  cursor: pointer;
}
.swiper-button-next,
.swiper-button-prev {
  height: 30px;
  width: 30px;
}
.swiper-pagination-bullet {
  background-color: #a1bbac;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #63b788;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  color: #63b788;
  font-size: 2rem;
  font-weight: 700;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .swiper-slide {
    padding: 2rem;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 0;
  }
  .swiper-container-rtl .swiper-button-next {
    left: -18px !important;
  }
  .swiper-container-rtl .swiper-button-prev {
    right: -18px !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: calc(24px / 10 * 27) !important;
    height: 34px !important;
  }
}

/** Blog **/

.blog-ad-container {
  padding: 8px 20px;
  background-color: #f8d7da;
  background-color: var(--lightRed);
  border-radius: 20px;
  color: #721c24;
  color: var(--redDanger);
  width: 60%;
  margin: 2rem 0;
}

.blog-recaptcha div,
.blog-recaptcha iframe {
  width: 100% !important;
}

.blog-search-button {
  padding: 0.375rem 0.75rem !important;
}

.featured-blogs .back-btn {
  right: 0vw;
}

.featured-blogs .next-btn {
  left: 0vw;
}

.featured-blogs .slider-arrow {
  position: absolute;
  font-size: calc(2rem + 0.5vw);
  border: none;
  color: #08cc96;
  color: var(--lightGreen);
  background-color: transparent;
  top: 12rem;
}

.moving-text {
  height: 25px;
  overflow: hidden;
  position: relative;
}
.moving-text font {
  position: absolute;
  white-space: nowrap;
  /* Starting position */
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  /* Apply animation to this element */
  -webkit-animation: moving-text 25s linear infinite;
  animation: moving-text 25s linear infinite;
}
/* Move it (define the animation) */
@-webkit-keyframes moving-text {
  0% {
    -webkit-transform: translateX(-10%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
@keyframes moving-text {
  0% { /* Firefox bug fix */
    -webkit-transform: translateX(-10%); /* Firefox bug fix */
    transform: translateX(-10%);
  }
  100% { /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%);
  }
}

/*Login Componenet*/
.login-wrapper {
  background: url(/static/media/terms-cover.6653eb6a.svg) center/cover;
}

.login-wrapper > .container {
  transition: 1s;
  margin-top: 3rem;
}

.login-form-wrapper {
  background-color: rgb(0 0 0 / 36%);
}

.login-form-wrapper:hover {
  transition: 1s;
  box-shadow: 0 0 13px 4px rgb(101 246 172 / 43%);
}

.custom-input-icon {
  position: absolute;
  right: 13px;
  top: 26%;
  bottom: 25%;
  z-index: 50;
}

.input-with-icon {
  padding-right: 3rem !important;
}

.component-wrapper {
  padding-top: 2rem;
  min-height: 100vh;
}

.custom-input {
  padding: 0 0.75rem;
  background-color: #313131;
  color: white;
}

.custom-input:focus,
.custom-input:active {
  background-color: #313131;
  color: white;
  border-color: #08cc96;
  border-color: var(--lightGreen);
}

.text-lightgreen {
  color: #08cc96;
  color: var(--lightGreen);
}

.btn-lightgreen {
  color: white;
  background-color: #08cc96;
  background-color: var(--lightGreen);
  border-color: #08cc96;
  border-color: var(--lightGreen);
  transition: 0.8s;
}

.bg-lightgreen {
  background-color: #08cc96;
  background-color: var(--lightGreen);
}

.login-logo {
  max-width: 110px;
  height: 75px;
}

/*Sign up*/

.sign-up-wrapper {
  background-image: url(/static/media/terms-cover.6653eb6a.svg);
}

.signup-form-section {
  padding: 3rem;
}

.birthdate-input::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.country-input::-webkit-scrollbar {
  background-color: #313131;
}

.country-input::-webkit-scrollbar-thumb {
  background-color: #08cc96;
  background-color: var(--lightGreen);
  border-radius: 15px;
}

.signup-logo {
  width: 140px;
  height: 140px;
  background: #fff;
  border-radius: 50%;
  padding: 28px;
}

.signup-info {
  background: none;
}

.signup-left-side {
  padding-top: 5rem;
  background-image: linear-gradient(90deg, rgb(255 0 0 / 0%), rgb(0 255 78 / 20%));
  margin-top: 0.4rem;
}

.signup-checkbox {
  width: 19px;
  height: 20px;
}

.signup-checkbox-label {
  margin-right: 1.5rem;
  line-height: 1.6;
}

.sugesstion-password-item::marker {
  color: #08cc96;
  color: var(--lightGreen);
}

/*Email Confirmation*/
.email-confirmation-icon {
  width: 15rem;
  border-radius: 18px;
}
.bg-second {
  background-color: #313131;
}

.bg-black {
  background-color: #161616;
}

/*Moblie Confirmation*/

.check-progress {
  background: #fff;
  color: #08cc96;
  color: var(--lightGreen);
  border-radius: 50%;
}

.box-title {
  margin-right: 3.2rem;
  padding-bottom: 3rem;
}

/*Dashboard*/
.section-icon {
  color: #08cc96;
  color: var(--lightGreen);
  margin-bottom: 10px;
}

.hacker-options {
  margin: 0.5rem 0;
}
.sub-hacker-list {
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media screen and (min-width: 435px) {
  .hacker-avatar {
    width: 55px;
    height: 50px;
    object-fit: cover;
  }
}

@media screen and (min-width: 372px) and (max-width: 434px) {
  .hacker-avatar {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 371px) {
  .hacker-avatar {
    width: 40px;
    height: 40px;
  }
  .sub-hacker-list {
    -webkit-justify-content: center;
            justify-content: center;
    margin: 0 auto;
  }
}

.navbar {
  -webkit-align-items: baseline;
          align-items: baseline;
}
.navbar-nav .dropdown-menu {
  position: absolute;
}

@media screen and (min-width: 371px) {
  .navbar-user-toggler {
    -webkit-order: 3;
            order: 3;
  }
  .sub-hacker-list {
    -webkit-order: 2;
            order: 2;
  }
}

.nav-icon {
  line-height: 2.6;
  margin: 0 0.8rem;
}

#hacker-profile::after {
  border: none;
}

.dbsidebar {
  position: fixed;
  top: 56px;
  bottom: 0;
  z-index: 99999;
  padding-right: 0;
  max-width: 10vw;
}

.right-dbsidebar {
  right: -23px;
}

.left-dbsidebar {
  left: 3px;
}

.dbsidebar .nav-item {
  text-align: center;
  padding: 25px 0;
}

.dbsidebar .nav-item:hover {
  background-color: #08cc96;
  background-color: var(--lightGreen);
  transition: 1.2s;
}

.dbsidebar .nav-item a.active {
  text-align: #08cc96;
  text-align: var(--lightGreen);
}

.vertical-nav {
  margin-top: 16vh;
}

.left-vertical-nav {
  margin-top: 10vh;
}

.dbmain {
  margin-top: 3.3rem;
}

@media screen and (max-width: 371px) {
  .dbmain {
    margin-top: 6rem;
  }
}

.hacker-image {
  width: 12rem;
  height: 11rem;
  object-fit: cover;
}

.hacker-social-icons {
  margin: 0 11px;
}

.hacker-stat-numbers {
  font-size: 1.8rem;
  font-weight: bold;
}

.badge-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.badge-name {
  font-weight: bold;
  font-size: calc(0.8rem + 1vw);
}

.badge-description {
  font-style: normal;
}

@media (max-width: 778px) {
  .vulbody {
    font-size: 1rem !important;
  }
}

@media (max-width: 600px) {
  .section-container {
    padding: 0 !important;
  }
  .vulbody {
    font-size: 1rem !important;
  }
  .hacker-skill-name.text-left.pl-2 {
    margin-bottom: 5px !important;
  }
  .left-dbsidebar {
    left: 50px;
  }
  .settings-button {
    padding: 15px !important;
  }
  table td,
  table th {
    padding: 13px !important;
  }
  .table td,
  .table th {
    letter-spacing: 0 !important;
  }
  .report-container {
    padding: 0 22px;
  }
  .dbsidebar {
    display: none !important;
  }
  .dbmain {
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .badge-img {
    width: 85%;
    height: 200px;
  }
}

.section-container {
  padding: 0 3rem;
}

/*Progress bar*/
.skill-container {
  direction: ltr;
}

.skill-progress {
  height: 2rem;
  background: linear-gradient(280deg, #0e5398, #0e5398a6, #0e539812, #161616);
  border: 2px #085baf solid;
  border-left: 0;
  border-radius: 0;
}

.skill-body {
  border: 1px rgb(219 219 219 / 56%) solid;
  border-radius: 5px;
  margin-bottom: 15px;
}

.skill-percent {
  margin: auto;
  font-weight: bold;
  letter-spacing: 1px;
}

.vulname {
  border-left: 3.4px solid #6b6b6b;
  width: 100%;
}

.vulcontainer {
  padding: 2rem;
}

.vultr {
  font-family: "Tajawal", sans-serif;
  font-size: 1.5rem;
}

.vulbody {
  font-family: "Tajawal", sans-serif;
  font-size: 1.4rem;
}

.companyLogo {
  width: 50px;
  height: 49px;
  border-radius: 50%;
  margin-left: 2rem;
  object-fit: cover;
}

/* Program */

.program-logo {
  max-width: 138px;
  height: 130px;
  border-radius: 54%;
  padding: 23px;
  object-fit: cover;
}

.program-stat {
  border-left: 3px rgb(0 0 0 / 48%) solid;
}

@media screen and (max-width: 768px) {
  .program-stat {
    border-left: 0;
    margin: 1rem 2rem;
    border-bottom: 3px rgb(0 0 0 / 48%) solid;
  }
}

.custom-nav-tabs {
  border-bottom: 1px solid #08cc96;
  border-bottom: 1px solid var(--lightGreen);
}

.nav-item > .program-nav-link {
  color: #08cc96 !important;
  color: var(--lightGreen) !important;
}

.nav-tabs .program-nav-link:hover {
  border: 1px solid #08cc96 !important;
  border: 1px solid var(--lightGreen) !important;
}

.nav-tabs .program-nav-link:focus {
  color: #08cc96 !important;
  color: var(--lightGreen) !important;
}

.stat-value {
  box-shadow: 0 1px 4px 2px rgb(22 22 22 / 48%);
}

.top-hackers-image {
  max-width: 55px;
  height: 55px;
  border-radius: 30px;
  object-fit: cover;
}

.program-bountry-bars-value {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
  color: rgb(228, 124, 6);
}

.program-bountry-bars {
  padding: 0.35em 1rem;
  margin-left: 0.5rem;
}

.inscope-assets-list {
  border-radius: 30px;
  width: 15px;
  height: 15px;
}

.program-home-tab-section {
  box-shadow: inset 0 0 4px 3px #dddddd42;
}

.program-activity-log-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.program-activity-log-item > p:last-child {
  margin: 0;
  margin-right: auto;
}

@media screen and (max-width: 470px) {
  .program-activity-log-item {
    -webkit-flex-flow: column;
            flex-flow: column;
  }
  .program-activity-log-item > * {
    margin: 0.5rem 0;
  }
  .program-activity-log-info {
    margin-top: 1.4rem;
  }
  .program-activity-log-item > p:last-child {
    margin: 1rem 0;
  }
}

/*Settings*/

@media screen and (max-width: 480px) {
  .notification-item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .notification-item > div {
    margin: 1rem 0;
    margin-left: auto;
  }
}

.settings-button {
  padding: 15px 42px !important;
  padding-right: 23px !important;
}

.settings-button:hover > a > svg {
  color: #fff;
}

.settings-button a:hover {
  color: #fff i !important;
}

.remove-profile-image {
  display: block;
  font-size: 2.5rem;
  position: absolute;
  padding: 0;
  margin: 0;
  top: -25px;
  right: 12px;
  cursor: pointer;
}

.remove-profile-image:hover {
  color: var(--red);
  transition: 1s;
}

/* enable absolute positioning */
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .glyphicon {
  position: absolute;
  pointer-events: none;
  top: 7px;
  left: 9px;
}

/* align icon */
.right-addon .glyphicon {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding-left: 2.5rem;
}
.right-addon input {
  padding-right: 30px;
}

/*Policy Images*/
.content-view {
  text-align: right;
  padding: 0 2rem;
}

.content-view > div > p > img,
.content-view > div > img,
.content-view > span > p > img,
.content-view > span > img {
  width: auto !important;
  max-height: 250px;
  padding: 1rem;
  border-radius: 20px;
}

@media screen and (max-width: 290px) {
  .content-view > div > p > img,
  .content-view > div > img,
  .content-view > span > p > img,
  .content-view > span > img {
    max-height: 150px;
    padding: 0.5rem;
  }
}

@media screen and (min-width: 291px) and (max-width: 350px) {
  .content-view > div > p > img,
  .content-view > div > img,
  .content-view > span > p > img,
  .content-view > span > img {
    max-height: 180px;
  }
}

.services-heading {
  font-family: system-ui;
  text-decoration: underline;
  -webkit-text-decoration-color: #08cc96;
          text-decoration-color: #08cc96;
  -webkit-text-decoration-color: var(--lightGreen);
          text-decoration-color: var(--lightGreen);
  margin-bottom: 4rem;
}

.services-title {
  margin-top: 4rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.services-title:before,
.services-title:after {
  content: "";
  -webkit-flex: 1 1;
          flex: 1 1;
  border-bottom: 1px solid;
  margin: auto 1rem;
}
.services-title:before {
  margin-right: 10px;
}
.services-title:after {
  margin-left: 10px;
}

.btn-join-us {
  background: rgb(0 0 0 / 24%);
  padding: 1rem 3rem;
  font-size: 2rem;
  margin: auto 0;
}

.btn-join-us:hover {
  box-shadow: 0 0 3px 4px #08cc96;
  box-shadow: 0 0 3px 4px var(--lightGreen);
  color: #08cc96;
  color: var(--lightGreen);
}

